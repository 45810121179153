var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [_c("el-card", [_vm._v("欢迎您" + _vm._s(_vm.userInfo.realname))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }